export const companyName = "Euclidxr Immersive pvt. ltd.";

export const twitterLink = "https://twitter.com/euclidxr";
export const linkedinLink= "https://www.linkedin.com/company/euclidxr";
export const contactEmail = "hello@euclidxr.com";

export const PAGES = {
  MAIN: '/',
  CONTACT_US: '/contact-us',
  JAPANESE: '/ja',
  JAPANESE_CONTACT_US: '/ja/contact-us',
  PRIVACY_POLICY: '/privacy-policy'
};

export const formDataFromJson = (json) => {
  const formData = new FormData();

  for ( const key in json ) {
    if(json.hasOwnProperty(key)) {
      formData.append(key, json[key]);
    }
  }
  return formData;
}
