/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.com/docs/gatsby-config/
 */

module.exports = {
  siteMetadata: {
    title: `Euclid`,
    description: `Enterprise-ready VR solutions on the web`,
    url: `https://www.euclidxr.com`,
    siteUrl: `https://www.euclidxr.com`,
    image: '/og_image.jpg',
    twitterUsername: '@euclidxr',
  },
  plugins: [
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Euclid`,
        short_name: `Euclid`,
        start_url: `/`,
        background_color: `#538EE9`,
        theme_color: `#538EE9`,
        display: `standalone`,
        icon: 'static/icon.png'
      },
    },
    `gatsby-plugin-sitemap`,
    {
      resolve: `gatsby-plugin-sass`,
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-gtag`,
      options: {
        trackingId: `G-CFVRGZVZJ5`
      },
    }
  ],
}
