import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
// import { useStaticQuery, graphql } from "gatsby";
import gatsbyConfig from "../../gatsby-config";

// const query = graphql`
//   query SEO {
//     site {
//       siteMetadata {
//         defaultTitle: title
//         titleTemplate
//         defaultDescription: description
//         siteUrl
//         defaultImage: image
//         twitterUsername
//       }
//     }
//   }
// `;

const Seo = ({ title, description, image }) => {
  const { pathname } = useLocation()
  // const { site } = useStaticQuery(query)
  // console.log(site);
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
    image: defaultImage,
    twitterUsername,
  } = gatsbyConfig.siteMetadata;

  const templatedTitle = (!title || (title === defaultTitle)) ? defaultTitle : `${title} | ${defaultTitle}`;

  const seo = {
    title: templatedTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet>
      <title>{templatedTitle}</title>
      <meta name="title" content={templatedTitle} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />

      <meta property="og:title" content={templatedTitle} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image} />

      <meta property="twitter:url" content={seo.url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

export default Seo;
