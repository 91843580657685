import React from 'react';
import { Helmet } from "react-helmet";
import Seo from "./seo";

class PageMetaDefault extends React.PureComponent {
  render() {
    const { pageTitle, description, image } = this.props;
    return (
      <>
        <Seo title={pageTitle} description={description} image={image} />
        <Helmet>
          <meta charSet="UTF-8" />

          {/* Apple specific tags. PWA icons are generated automatically by gatsby plugin */}
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />

          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="HandheldFriendly" content="true" />
          <meta name="MobileOptimized" content="360" />

          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Jost:wght@800&family=Source+Sans+Pro:wght@400;900&display=swap" rel="stylesheet" />
        </Helmet>
      </>
    );
  }
}

export default PageMetaDefault;
